.hero{
    background: #e4e4e7;
    display: flex;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
}

.hero_image{
    flex: 0 0 40%;
}

.avatar{
    max-height: 30rem;
    max-width: 100%;
    border-radius: 1rem;
    margin: 2rem;
    transform: perspective(1000px) rotateY(20deg); /* Adjust the perspective and rotation angle as needed */
}

.introduction{
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
}

.name{
    color: #0c0a09;
    font-size: 3rem;
    font-weight: 700;
}

.subtitle{
    color: #0c0a09;
    font-weight: 500;
    font-size: 2rem;
}

.body{
    color: #0c0a09;
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;
}

@media (max-width: 767px) {
    .hero{
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .hero_image{
        margin-top: 1rem;
        flex: 0 0 100%;
    }
    .avatar{
        margin: 0;
    }
    .introduction{
        flex: 0 0 100%;
        padding: 0;
    }
    .body{
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1.5;
    }
}

.iframe{
    position:fixed;
    top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;
}
