.container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.social_wrapper{
    max-width: 100%;
    display: flex;
    margin-bottom: 2rem;
}
.social_platform{
    cursor: pointer;
    display: block;
    margin-top: 0.5rem;
    display: block;
    background-color: white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}
.github_logo{
    object-fit: contain;
}
.linkedIn_logo{
    object-fit: cover;
}

.connect{
    color: #0c0a09;
    font-weight: 500;
    font-size: 2rem;
}


@media screen and (max-width: 600px) {
    .social-platform{
        width: 2rem;
        height: 2rem;
    }
}
