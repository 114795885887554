.container{
    display: flex;
    flex-direction: row;
    min-width: 100%;
    justify-content: center;
}

.loading {
    margin-top: 1rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: #e4e4e7;
    animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}