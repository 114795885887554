.outer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.class_card{
    margin: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 10px 0px;
    padding: 1rem;
    transition: box-shadow 0.2s;
    position: relative;
    cursor: pointer;
}

.class_card:hover {
    transform: scale(0.99); /* Scale the card up when hovered */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
}

.class_card:active {
    transform: scale(0.99); /* Scale the card up when hovered */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
}

.className{
    font-weight: bold;
    font-size: 2rem;
}

.subject{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 10px 0px;
    margin-right: 0.5rem;
    display: inline;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
}

.total_notes{
    margin-top: 2rem;
}

@media (max-width: 767px) {
    .class_card{
        margin: 1rem;
    }
}

.heading{
    color: #0c0a09;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
