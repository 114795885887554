.container{
    display: flex;
    flex-direction: column;
}

.class_card{
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 1rem;
    transition: box-shadow 0.2s;
    position: relative;
    cursor: pointer;
}

.subjectName{
    font-weight: bold;
    font-size: 2rem;
    margin: 0.5rem 2rem;
}

.className{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    font-weight: bold;
    font-size: 2rem;
}

.subject{
    margin-right: 0.5rem;
    display: inline;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
}

.total_notes{
    margin-top: 2rem;
}

@media (max-width: 767px) {
    .class_card{
        margin: 1rem;
    }
}

/* CollapsibleContainer.css */

.topContainer {
    border: 1px solid #e4e4e7;
    max-width: 100%;
    margin: 0.5rem 2rem;
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
}

.header {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
}

.collapsibleContainer{
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.collapsibleContainer.expanded{
    display: flex;
}

.collapsibleContainer.collapsed{
    display: none;
}


.cardHeader{
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.fileName{
    flex-grow: 1;
    overflow-x: hidden;
}

.fileLink{
    padding-left: 10px;
    padding-right: 10px;
    color: #1da1f1;
    cursor: pointer;
}
